import { RegisterPageButton } from '@features/registration';
import SpecialOfferModal from '@features/special-offer/SpecialOfferModal';
import { ClientLogos } from '@widgets/client-logos';
import { FeatureLayout } from '@widgets/layout';
// ui
// constants
import { TARIFFS, TARIFFS_CHECK_LIST } from '@assets/content/constants';
// lang
import { RequestFeedback } from '@features/request-feedback';
// hooks
import { t } from '@lib/i18n';
import { useMediaQuery } from '@hooks/useMediaQuery';
import MediaQuery from '@ui/MediaQuery';
import PriceBlock from '@ui/Price/PriceBlock';
import SupportBlock from '@ui/Price/SupportBlock';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
// styles
import { block } from 'bem-cn';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import './style.scss';

const cn = block('price-page');

// ---------------------------------------------------------------------

const tr = (key) => t(`tariffs.${key}`);
const FeatureContent = () => (
  <>
    <Typography variant="header1">
      {tr('header')}
    </Typography>
    <Typography variant="subhead1">
      {tr('subheader')}
    </Typography>
  </>
);

const Price = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  // const [open, setOpen] = useState(false);

  // пока отключаем акцию и убираем модалку
  
  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpen(true);
  //   }, 2000);
  // }, []);

  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <FeatureLayout
      mainLayoutProps={{ seo: t('seo.price'), banner: true }}
      featureChildren={<FeatureContent />}
      className="!pb-[160px] sm:!pb-[277px] md:!pb-[162px]"
    >
      <RequestFeedback.Price.Modal />
      {/* <SpecialOfferModal open={open} onClose={closeModal} /> */}
      <div className="sm:mt-[-213px] md:mx-4 md:mt-8 md-up:max-w-[976px] lg:mx-auto lg:mt-9">
        <div className="pb-9 sm:pb-7 md:pb-8">
          <section className={cn('layout')} data-section="price">
            <div className={cn('quick_start')
              .mix(clsx('md-down:hidden'))}
            >
              <div className={cn('quick_start_bg')}>
                <div className="flex justify-between gap-6 rounded-[15px] px-4 py-5.5">
                  <div>
                    <div className="flex items-end gap-[14px]">
                      <Typography as="h3" className="text-3xl font-semibold">
                        {TARIFFS[0].name}
                      </Typography>
                      <Typography className="h-min rounded-full bg-tertiary200 px-2 py-[6px] text-xs text-secondary400">
                        {TARIFFS[0].price}
                      </Typography>
                    </div>
                    <Typography className="mt-[14px] !text-secondary300">
                      {TARIFFS_CHECK_LIST.filter((item) => TARIFFS[0].check_list_id.includes(item.id))
                        .map((item) => item.name).join('. ').concat('.')}
                    </Typography>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <RegisterPageButton className="w-[240px]">
                      {t('tariffs.registration')}
                    </RegisterPageButton>
                    <Typography className="text-2xs !text-secondary100">
                      {TARIFFS[0].description}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <MediaQuery greaterThan="md">
              <div
                className={
                  cn('tariffs', { wrap: true })
                    .mix(clsx(
                      'md-up:grid-cols-3 lg:mt-4 lg:gap-3 xl:gap-4',
                    ))
                }
              >
                {TARIFFS.map((tariff, index) => {
                  if (isDesktop && index === 0) {
                    return null;
                  }
                  return (
                    <div
                      key={tariff.name}
                      className={cn('tariffs', { item: true })}
                    >
                      <PriceBlock tariff={tariff} />
                    </div>
                  );
                })}

              </div>
            </MediaQuery>
            <MediaQuery at="md">
              <div className="mt-3">
                <Slider settings={{ slidesToShow: 2.4 }} gap={6}>
                  {TARIFFS.map((tariff, index) => {
                    if (isDesktop && index === 0) {
                      return null;
                    }
                    return (
                      <div
                        key={tariff.name}
                        className={cn('tariffs', { item: true })}
                      >
                        <PriceBlock tariff={tariff} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </MediaQuery>
            <MediaQuery lessThan="md">
              <div className="">
                <Slider gap={6}>
                  {TARIFFS.map((tariff, index) => {
                    if (isDesktop && index === 0) {
                      return null;
                    }
                    return (
                      <div
                        key={tariff.name}
                        className={cn('tariffs', { item: true })}
                      >
                        <PriceBlock tariff={tariff} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </MediaQuery>
            <Typography color="secondary100" className="mt-7 text-center text-2xs sm:mt-5 md:mt-6">
              {t('tariffs.price.subtitle')}
            </Typography>
          </section>
        </div>
      </div>
      <Typography variant="header2" as="h2" className="mx-auto mt-9 block max-w-[1024px] px-6 text-center font-medium sm:mt-7 md:mt-8 md:font-semibold lg:font-semibold">
        {t('tariffs.feedback.title')}
      </Typography>
      <ClientLogos />
      <div className="mx-auto max-w-[1312px]">
        <section
          className={
            cn('layout', { faq: true })
          }
        >
          <SupportBlock />
        </section>
      </div>
    </FeatureLayout>
  );
};

export default Price;
